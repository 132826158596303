// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;

// @codekit-prepend "cycle2/jquery.cycle2.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.autoheight.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.center.js" quiet;

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {

	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
	});

	function closeNav() {
		$('body').removeClass('bodyfix');
		$('.hamburger').removeClass('is-active');
		$('header nav').removeClass('is-active');
	}

	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			closeNav();
		}
	});

	// close menu on click outside of the nav
	$(document).click(function(event) {
		var target = $(event.target);
		if (!target.closest('#main-nav').length && !target.closest('.hamburger').length) {
			closeNav();
		}
	});
	// ------------------------------------------------------------------

	// accordion
	$('.accordion').on('click', 'h3', function() {
		$(this).toggleClass('open');
		$(this).find('button').attr('aria-expanded', function(i, attr) {
			return attr == 'true' ? 'false' : 'true'
		});
		$(this).siblings().removeClass('open');
		$(this).next('.copy-wrapper').slideToggle(300).toggleClass('close');
		$(this).siblings().next('.copy-wrapper').slideUp(300);
		$(this).siblings().find('button').attr('aria-expanded', false);
	});

	// --------------------------------------------------------------
	// request from 
	function requestFormSubmit() {

		var honeypot = $('#request-form input[name="email_confirm"]').val();
		var private_company = $('#request-form input[name="private_company"]:checked').val();
		var first_name = $('#request-form input[name="first_name"]').val();
		var name = $('#request-form input[name="name"]').val();
		var email = $('#request-form input[name="email"]').val();
		var phone = $('#request-form input[name="phone"]').val();
		var message = $('#request-form textarea[name="message"]').val();
		var billing_name = $('#request-form input[name="billing_name"]').val();
		var street = $('#request-form input[name="street"]').val();
		var city = $('#request-form input[name="city"]').val();
		var confirm_costs = $('#request-form input[name="confirm_costs"]').is(":checked") ? true : false;
		var confirm_data_processing = $('#request-form input[name="confirm_data_processing"]').is(":checked") ? true : false;

		$.ajax({
			type: 'POST',
			url: varlam_object.ajax_url,
			data: {
				action: 'submit_request_form',
				nonce: varlam_object.nonce,
				private_company: private_company,
				first_name: first_name,
				name: name,
				email: email,
				phone: phone,
				message: message,
				billing_name: billing_name,
				street: street,
				city: city,
				confirm_costs: confirm_costs,
				confirm_data_processing: confirm_data_processing,
			},
		}).done(function(data) {
			if (data == true || data == '1') {
				$('#request-form .success').show();
			} else {
				$('#request-form #error-submit').show();
			}
		}).fail(function(xhr, textStatus, errorThrown) {
			console.error('Error:', xhr.statusText, xhr.responseText);
			$('#request-form #error-submit').show();
		});

	}


	function requestFormValidate() {
		// Select all input fields with the aria-required attribute

		var valid = true;

		// honeypot check
		var honeypot = $('#request-form input[name="email_confirm"]').val();
		if (!empty(honeypot)) {
			console.log('You are a robot!');
			valid = false;
		}

		// Run through the input fields and check whether they are valid
		$('#request-form input[aria-required="true"]').each(function() {
			if (empty($(this).val())) {
				valid = false;
				showError($(this));
				//console.log('not all required fields are filled');
			} else {
				hideError($(this));
			}
		});
		// validate radio buttons
		if ($("#request-form input[name='private_company']:checked").length == 0) {
			valid = false;
			$('#request-form .private-company').addClass('not-valid');
			$('#request-form .private-company .error').stop().slideDown(200);
			// console.log('private_company: false');
		} else {
			$('#request-form .private-company').removeClass('not-valid');
			$('#request-form .private-company .error').stop().slideUp(200);
			// console.log('private_company: true');
		}

		// validate email 
		var email_regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		var email = $('#request-form input[name="email"]').val();
		if (!empty(email) && !email_regex.test(email)) {
			valid = false;
			$('#request-form input[name="email"]').closest('.field-wrapper').addClass('not-valid');
			$('#request-form input[name="email"]').closest('.field-wrapper').find('.error-email').stop().slideDown(200);
			// console.log('email not valid');
		} else {
			hideError($(this));
			$('#request-form input[name="email"]').closest('.field-wrapper').find('.error-email').stop().slideUp(200);
		}

		// validate the message text area 
		var message = $('#request-form textarea[name="message"]');
		if (empty($(message).val())) {
			valid = false;
			showError(message);
			// console.log('textarea not valid')
		} else {
			hideError(message);
			// console.log('textarea valid');
		}

		// validate postalcode plus city
		var city_regex = /^(\d{5})\s+([a-zA-ZÄäÀàÁáÂâÃãÅåÆæÇçÐðÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöØøŒœÞþÙùÚúÛûÜüÝýŸÿŠšŽž/-–—ß\s-]+)$/;
		var city = $('#request-form input[name="city"]').val();
		if (!empty(city) && !city_regex.test(city)) {
			valid = false;
			$('#request-form input[name="city"]').closest('.field-wrapper').addClass('not-valid');
			$('#request-form input[name="city"]').closest('.field-wrapper').find('.error-city').stop().slideDown(200);
			console.log('city not valid');
		} else {
			//hideError($(this));
			$('#request-form input[name="city"]').closest('.field-wrapper').removeClass('not-valid');
			$('#request-form input[name="city"]').closest('.field-wrapper').find('.error-city').stop().slideUp(200);
		}

		// validate confirm checkbox
		var confirm_costs = $("#request-form input[name='confirm_costs']").prop('checked');
		if (confirm_costs == false) {
			valid = false;
			$('#request-form input[name="confirm_costs"]').closest('.field-wrapper').addClass('not-valid');
			$('#request-form input[name="confirm_costs"]').closest('.field-wrapper').find('.error').stop().slideDown(200);
			// console.log('confirm_costs false');
		} else {
			$('#request-form input[name="confirm_costs"]').closest('.field-wrapper').removeClass('not-valid');
			$('#request-form input[name="confirm_costs"]').closest('.field-wrapper').find('.error').stop().slideUp(200);
		}

		// validate confrim checkbox
		var confirm_data_processing = $("#request-form input[name='confirm_data_processing']").prop('checked');
		if (confirm_data_processing == false) {
			valid = false;
			$('#request-form input[name="confirm_data_processing"]').closest('.field-wrapper').addClass('not-valid');
			$('#request-form input[name="confirm_data_processing"]').closest('.field-wrapper').find('.error').stop().slideDown(200);
		} else {
			$('#request-form input[name="confirm_data_processing"]').closest('.field-wrapper').removeClass('not-valid');
			$('#request-form input[name="confirm_data_processing"]').closest('.field-wrapper').find('.error').stop().slideUp(200);
		}

		// general error
		if (valid !== true) {
			$('#error-required-general').stop().slideDown(200);
		} else {
			$('#error-required-general').stop().slideUp(200);
		}
		// console.log('form valid true');
		// If all input fields are valid, reset the error
		return valid;
	}

	function empty(value) {
		if (value.trim() != '') {
			return false;
		}
		return true;
	}

	function showError(thisElem) {
		$(thisElem).closest('.field-wrapper').addClass('not-valid');
		$(thisElem).closest('.field-wrapper').find('.error').stop().slideDown(200);
	}

	function hideError(thisElem) {
		$(thisElem).closest('.field-wrapper').removeClass('not-valid');
		$(thisElem).closest('.field-wrapper').find('.error').stop().slideUp(200);
	}

	// revalidate private_company radios
	$('#request-form').on('change', '.not-valid input[name="private_company"]', function() {
		requestFormValidate();
	});

	// revalidate confirm checkboxes
	$('#request-form').on('change', '.not-valid input[name="confirm_costs"]', function() {
		requestFormValidate();
	});

	// revalidate confirm checkboxes
	$('#request-form').on('change', '.not-valid input[name="confirm_data_processing"]', function() {
		requestFormValidate();
	});

	// revalidate confirm checkboxes
	$('#request-form').on('input', '.not-valid input[name="city"]', function() {
		console.log('reval city');
		requestFormValidate();
	});

	// revalidate error fields on blur
	$('#request-form').on('blur', '.not-valid input, .not-valid textarea', function() {
		requestFormValidate();
	});


	$('#request-form').on('submit', function(e) {
		e.preventDefault();
		//console.log('start submit');

		var valid = requestFormValidate();
		if (valid) {
			//console.log('valid start submit');
			$('#request-form button[type="submit"]').prop("disabled", true);
			requestFormSubmit();
		}
	});


})(jQuery);